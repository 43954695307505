/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  width: 100%;
  height: 100%;
  font-family: PingFang SC, Hiragino Sans GB, Arial, Microsoft YaHei, Verdana, Roboto, Noto, Helvetica Neue;
  /* stylelint-disable-next-line selector-max-id */
}
body :global #app {
  background: #f0f0f0;
  height: 100%;
}
@media screen and (min-width: 1441px) {
  body :global .login-page {
    background-image: url('../assets/images/BigGround1926.png') !important;
  }
  body :global .login-content-wrapper {
    align-items: center;
    width: 1360px !important;
  }
}
@media screen and (min-height: 778px) {
  body :global .login-bottom {
    position: absolute;
    bottom: 28px;
  }
  body :global .login-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
body :global .sys-table {
  margin-bottom: 16px;
}
body :global .sys-pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
