.selectModal :global .ant-modal-confirm-btns {
  display: none;
}
.selectModal :global .ant-list-item {
  cursor: pointer;
  text-align: center;
  margin-left: -38px;
  font-size: 14px;
  padding: 16px 0;
}
.selectModal :global .ant-list-item:hover {
  background-color: #fafafa;
}
